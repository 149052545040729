// Initialize first
@import '../init';

// Import files
@import '_home';

// Add your style here
.home {

  // Versi Homepage
  &--v1 {
    position: relative;
    width: 100%;
    padding-bottom: 40px;

    @include media-breakpoint-down(xl) {
      padding: 0px 20px;
    }
  }

  // Component Homepage
  &__title {
    position: relative;
    display: flex;
    padding-top: 180px;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;

    h1 {
      color: $color-white;
      max-width: 823px;
    }

    p {
      max-width: 452px;
      color: $color-lighter-grey;
      margin-top: 20px;
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 44px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      // width: 100%;

      .btn {
        width: 100%;
      }
    }

    .btn {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      -moz-border-radius: 10px;
      -khtml-border-radius: 10px;
      transition: 0.2s;
    }
  }

  &__ilustration {
    position: relative;
    margin: auto;
    margin-top: toRem(48);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: calc(1016 / 1100 * 100%);
      height: auto;
      box-shadow: $shadow-lg;
    }
  }
  &__feature__illustration{
    img{
      border-radius: 32px;
      box-shadow: 8px 8px 16px 0px rgba(13, 86, 177, 0.20);
      
    }
    @include media-breakpoint-down(sm) {
      img{
        object-fit: cover;
        object-position: top;
      }
    }
    @include media-breakpoint-down(md) {
      img{
        object-fit: cover;
        object-position: top;
      }
    }
    @include media-breakpoint-down(xl) {
      img{
        object-fit: cover;
        object-position: top;
      }
    }
  }

  &__content-why {
    margin-top: 20px;
    &--article {
      position: relative;
      height: 597px;
      display: flex;
      margin-top: 64px;
      gap: 24px;

      
      
    }
    
    &__details {
      &--about {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 0.5;
        gap: 24px;
      }
      
      &__detail {
        @include media-breakpoint-down(sm) {
          flex-direction: row;
          width: 100%;
          padding: 2px 5px;
          h4{
            font-size: 18px;
          }
        }
        @include media-breakpoint-down(md) {
            flex-direction: row;
            width: 100%;
            .icon-large{
              width: 228px;
              height: 128px;
              img{
                width: 70px;
              }
            }
          }
          @include media-breakpoint-down(xl) {
            flex-direction: row;
            width: 100%;
            padding: 2px 10px;
            h4{
              font-size: 18px;
            }
            .icon-large{
              width: 228px;
              height: 128px;
              img{
                width: 70px;
              }
            }
          }
        width: 500px;
        height: 185px;
        gap: 30px;
        padding-left: 30px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #0D56B1;


        div{
          background-color: #fff;
        }
        .icon-large{
          width: 128px;
          height: 128px;
          img{
            width: 70px;
          }
        }
        &--about {
          @include media-breakpoint-down(lg) {
            display: flex;
            text-align: center;
            justify-content: center;
            flex-direction: column;
            align-items: center;
          }
          

          h4 {
            color: $color-white;
            margin: 0;
            text-transform: capitalize;
          }

          p.text-m-regular {
            color: $color-grey;
            margin-top: 10px;
          }
        }

        &--about-1 {
          text-align: center;

          .icon-large {
            margin: auto;
            
          }

          h4 {
            color: $color-white;
            margin: auto;
            text-transform: capitalize;
          }

          p.text-m-regular {
            color: $color-grey;
            margin: auto;
            margin-top: 10px;
            max-width: 287px;
            @include text-shorten($line: 3);
          }
        }
      }
    }

    &__illustration {
      position: relative;
      flex: 1;
      height: 100%;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      img {
        position: absolute;
        height: 120%;
        width: 120%;
        top: -90px;
        left: -50px;
      }
    }
  }

  &__btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    @include media-breakpoint-down(xl) {
      display: none;
    }

    &--about {
      margin-left: auto;
      margin-right: auto;
      margin-top: -24px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &__btn-2 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}

.m-b-4 {
  margin-bottom: -210px;

  @include media-breakpoint-down(lg) {
    margin-bottom: -180px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: -160px;
  }
}

.preview-images {
  display: flex;
  flex-direction: column;
  gap: toRem(32);
  padding: toRem(104) 0;
  overflow-x: hidden;

  @include media-breakpoint-down(md) {
    gap: toRem(24);
  }

  @include media-breakpoint-down(sm) {
    gap: toRem(16);
    padding: toRem(64) 0;
  }

  .preview-wrap {
    width: 100vw;
    display: flex;
    flex-direction: row;
    height: fit-content;
    max-height: 60px;
    gap: toRem(46);
    position: relative;
    margin: auto;
    overflow-x: hidden;

    &::after {
      width: 99vw;
      height: fit-content;
      max-height: 320px;
      content: '';
      position: absolute;
    }

    &--up {
      justify-content: flex-start;
    }

    &--down {
      justify-content: flex-end;
    }

    @include media-breakpoint-down(md) {
      gap: toRem(24);
    }

    @include media-breakpoint-down(sm) {
      gap: toRem(16);
    }

    .preview-image-animate {
      display: flex;
      flex-direction: row;
      gap: toRem(32);
      flex-wrap: nowrap;
      position: relative;

      &--first {
        left: toRem(-50);
      }

      &--second {
        right: toRem(-50);
      }

      @include media-breakpoint-down(md) {
        gap: toRem(24);
      }

      @include media-breakpoint-down(sm) {
        gap: toRem(16);
      }

      .header-img {
        border-radius: 10px;
        box-shadow: $shadow-md;
        height: 60px;
        width: auto;

        @include media-breakpoint-down(md) {
          height: 50px;
        }

        @include media-breakpoint-down(sm) {
          height: 50px;
        }
      }
    }
  }
}